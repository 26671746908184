import { Component, ContentChildren, ElementRef, HostListener, Input, QueryList, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Placement, PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { getDynamicId } from '@backbase/ui-ang/util';

export interface ProductItem {
  id: string;
  name?: string;
  currency?: string;
  amount?: string;
  productNumber?: string;
  [key: string]: any;
}

/* eslint-disable */
/**
 * @name ProductSelectorComponent
 *
 * @description
 * A dropdown component that displays items in a pre-defined format.
 *
 * @a11y
 * Every item from the list in the custom templates need to match the following requirements:
 * * It should have a `role="option"` attribute for detection by Screen Reader like option.
 * * It should have a `#listItem` hash tag for keyBoard accessibility (navigate with keyUp/keyDown)
 * * It should use a `div` HTML element instead of a button for its host HTML element
 * * It should have a `tabindex="0"`
 *
 * @usageNotes
 * Here is a example of custom tempates for the list items:
 * ```
 * <ng-container bbDropdownMenu>
 *   <div
 *     *ngFor="let item of productsInterfaced; let i = index"
 *     (click)="productSelected = productsInterfaced[i]"
 *     \#listItem
 *     role="option"
 *     tabindex="0"
 *     class="bb-product-selector__dropdown-item"
 *     [ngClass]="{ selected: item.id === productSelected?.id }"
 *   >
 *     <bb-product-item-basic-account-ui
 *       [title]="item.name"
 *       [amount]="item.amount"
 *       [productNumber]="item.productNumber"
 *       [currency]="'EUR'"
 *       [highlight]="true"
 *       [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
 *       [active]="item.id === productSelected?.id"
 *     ></bb-product-item-basic-account-ui>
 *   </div>
 * </ng-container>
 * ```
 *
 */
/* eslint-enable */
@Component({
  selector: 'bb-product-selector-ui',
  templateUrl: './product-selector.component.html',
})
export class ProductSelectorComponent {
  /**
   * Focused item index from product selector list
   */
  focusedItemIndex = -1;
  /**
   * State of focus on product selector element
   */
  focusState = '';
  /**
   * Open/close state of dropDown menu
   */
  isOpen = false;
  /**
   * Whether the dropdown should be closed when clicking one of dropdown items or pressing ESC.
   * Defaults to true.
   */
  @Input() autoClose = true;

  /**
   * Generates ID for dropdown element. It is required for the `aria-controls` attribute
   */
  dropdownId = 'product_selector_dropdown_' + getDynamicId();

  /**
   * A unique identifier for the product-selector toggle element.
   */
  @Input() id?: string;

  /**
   * The position of the dropdown, position will be picked in order of feasibility.
   */
  @Input() position: Placement | PlacementArray = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];

  /**
   * It identifies the currently active element in the selector.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-activedescendant') ariaActivedescendant?: string;

  /**
   * Set `aria-describedby` with an element id that contains a detailed decription of the widget.
   * It is used to establish a relationship between widgets or groups and the text that describes them.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') ariaDescribedby?: string;

  /**
   * Set `ariaLabelForDropdown` with a text that explains what dropdown component is about
   */
  @Input() ariaLabelForDropdown = 'A dropdown component with a product list';

  /**
   * Set `ariaLabelForToggleButton` with a text that explains what a toggle button is about
   */
  @Input() ariaLabelForToggleButton = 'A toggle button for a product list';

  /**
   * Specifies which element the dropdown should be appended to.
   */
  @Input() container: '' | 'body' = '';

  isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
  @ViewChild('bbDropdown', { static: true }) bbDropdown: NgbDropdown | undefined;
  @ViewChild('dropdownToggle') dropdownToggle!: ElementRef;
  @ContentChildren('listItem') listItems!: QueryList<ElementRef>;

  @HostListener('window:keydown', ['$event'])
  onKeyEvent(event: KeyboardEvent) {
    if (this.isOpen) {
      switch (this.isIE ? event.keyCode : event.key) {
        case 40:
        case 'ArrowDown':
          this.focusedItemIndex = Math.min(this.focusedItemIndex + 1, this.listItems.length - 1);
          this.focusItem();
          break;
        case 38:
        case 'ArrowUp':
          this.focusedItemIndex = Math.max(this.focusedItemIndex - 1, 0);
          this.focusItem();
          break;
        case 'Enter':
          this.clickItem();
          break;
        default:
          return;
      }
      event.preventDefault();
    }
  }

  close() {
    if (this.bbDropdown && this.autoClose && this.bbDropdown.isOpen()) {
      this.bbDropdown.close();
    }
  }

  open() {
    if (this.bbDropdown && !this.bbDropdown.isOpen()) {
      this.bbDropdown.open();
    }
  }

  onClose() {
    if (this.autoClose) {
      this.close();
      this.dropdownToggle.nativeElement.focus();
    }
  }

  focusItem() {
    this.listItems.forEach((el, index) => {
      if (index === this.focusedItemIndex) {
        el.nativeElement.tabIndex = 0;
        el.nativeElement.focus();
      }
    });
  }

  private clickItem() {
    this.listItems.forEach((el, index) => {
      if (index === this.focusedItemIndex) {
        el.nativeElement.click();
      }
    });
  }

  /**
   * Event handler for change open/close state in dropdown menu
   */
  onOpenChange($event: boolean) {
    this.isOpen = $event;
    if (!this.isOpen) {
      this.focusedItemIndex = -1;
    }
  }

  /**
   * Event handler for blur dropdown menu
   */
  onBlur() {
    if (this.bbDropdown && this.bbDropdown.isOpen()) {
      this.bbDropdown.close();
    }
  }
}
