<div
  class="bb-input-password"
  [ngClass]="{ 'ng-invalid': parentFormControl?.invalid, 'ng-touched': parentFormControl?.touched }"
>
  @if (label) {
    <label class="form-label" for="{{ id }}">{{ label }}</label>
  }
  <div class="input-group">
    <input
      id="{{ id }}"
      #inputPassword
      [attr.aria-autocomplete]="ariaAutocomplete"
      [attr.aria-controls]="ariaControls"
      [attr.aria-labelledby]="ariaLabelledby"
      [attr.aria-describedby]="ariaDescribedby"
      [attr.aria-expanded]="ariaExpanded"
      [attr.aria-activedescendant]="ariaActivedescendant"
      [attr.role]="role"
      [attr.aria-invalid]="ariaInvalid"
      [attr.aria-owns]="ariaOwns"
      [attr.aria-label]="(!label && ariaLabel) || null"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      (blur)="onBlur()"
      [type]="showPassword ? 'text' : 'password'"
      class="form-control bb-input-password__input"
      (focus)="onFocus()"
      [disabled]="disabled"
      [readonly]="readonly"
      [placeholder]="placeholder"
      [required]="required"
      [bbFocus]="autofocus"
      [attr.autocomplete]="autocomplete"
      [attr.maxlength]="maxLength"
      [attr.minlength]="minLength"
      data-role="input-password"
    />
    @if (showVisibilityControl) {
      <button
        bbButton
        (click)="onVisibilityChange()"
        color="tertiary"
        [attr.aria-label]="visibilityControlLabel"
        class="bb-input-password__visibility-toggle-button"
        data-role="bb-input-password-visibility-toggle-button"
      >
        <bb-icon-ui [name]="showPassword ? 'visibility-off' : 'visibility'" size="md"></bb-icon-ui>
      </button>
    }
  </div>
</div>
