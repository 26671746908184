import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { Notification, NotificationAlertType } from '@backbase/ui-ang/notification';
import { NotificationService } from '@backbase/ui-ang/notification';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-notification-ui-showcase',
  templateUrl: 'notification-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class NotificationShowcaseComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild('default1', { static: true }) default1: TemplateRef<any> | undefined;
  @ViewChild('default2', { static: true }) default2: TemplateRef<any> | undefined;
  @ViewChild('messageTemplate', { static: true }) messageTemplate: TemplateRef<any> | undefined;
  notificationsConfig!: Array<any>;

  ngAfterViewInit(): void {
    this.notificationsConfig = [
      {
        btn: {
          text: 'Launch info notification',
          color: 'primary',
          type: 'info',
        },
        notification: {
          header: 'This is the header of notification',
          message: 'This is the default info notification. It breaks words, so you can have long text here.',
          modifier: 'info' as NotificationAlertType,
          dismissible: false,
          forceReadout: true,
          ttl: 4500,
        },
      },
      {
        btn: {
          text: 'Launch success notification',
          color: 'success',
          type: 'success',
        },
        notification: {
          header: 'This is the header of notification',
          message: 'This is the default success notification. It breaks words, so you can have long text here.',
          modifier: 'success' as NotificationAlertType,
          dismissible: true,
          ttl: 5000,
        },
      },
      {
        btn: {
          text: 'Launch alert notification',
          color: 'danger',
          type: 'danger',
        },
        notification: {
          header: 'This is the header of notification',
          message: 'This is the default error notification. It breaks words, so you can have long text here.',
          modifier: 'error' as NotificationAlertType,
          dismissible: true,
          ttl: 10000,
        },
      },
      {
        btn: {
          text: 'Launch warning notification',
          color: 'primary',
          type: 'warning',
        },
        notification: {
          ttl: 10000,
          header: 'This is the header of notification',
          message: 'This is the default warning notification. It breaks words, so you can have long text here.',
          modifier: 'warning' as NotificationAlertType,
          dismissible: true,
        },
      },
      {
        btn: {
          text: 'Launch two buttons notification',
          color: 'primary',
          type: 'two-buttons',
        },
        notification: {
          ttl: 5000,
          header: 'This is the header of notification',
          message:
            'This is a notification with both primary and secondary actions. Both buttons allow callbacks and will close the notification when pressed. This notification also has no timeout, ensure this by setting ttl to 0.',
          modifier: 'error' as NotificationAlertType,
          dismissible: true,
          primaryAction: () => this.showMessage('primary'),
          secondaryAction: () => this.showMessage('secondary'),
          primaryActionText: 'Primary',
          secondaryActionText: 'Secondary',
          autofocus: true,
        },
      },
      {
        btn: {
          text: 'Launch custom notification with context',
          color: 'primary',
          type: 'custom',
        },
        notification: {
          ttl: 10000,
          header: 'File was uploaded',
          message: 'Sample file.txt. was successfully uploaded',
          modifier: 'success' as NotificationAlertType,
          dismissible: true,
        },
      },
      {
        btn: {
          text: 'Launch two customisable buttons notification',
          color: 'primary',
          type: 'two-buttons',
        },
        notification: {
          ttl: 0,
          header: 'This is the header of notification',
          message:
            'This is a notification with both primary and secondary actions. Both buttons allow callbacks and will close the notification when pressed. This notification also has no timeout, ensure this by setting ttl to 0.',
          modifier: 'error' as NotificationAlertType,
          dismissible: true,
          primaryActionTemplate: this.default1,
          secondaryActionTemplate: this.default2,
          primaryAction: () => this.showMessage('primary'),
          secondaryAction: () => this.showMessage('secondary'),
          primaryActionText: 'Primary',
          secondaryActionText: 'Secondary',
          autofocus: true,
        },
      },
      {
        btn: {
          text: 'Launch htm template text',
          color: 'primary',
          type: 'two-buttons',
        },
        notification: {
          ttl: 0,
          header: 'This is the header of notification',
          message: this.messageTemplate,
          modifier: 'error' as NotificationAlertType,
          dismissible: true,
          primaryActionTemplate: this.default1,
          secondaryActionTemplate: this.default2,
          primaryAction: () => this.showMessage('primary'),
          secondaryAction: () => this.showMessage('secondary'),
          primaryActionText: 'Primary',
          secondaryActionText: 'Secondary',
        },
      },
    ];
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  constructor(
    private readonly notificationService: NotificationService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  showNotification({
    header,
    message,
    dismissible,
    ttl,
    modifier,
    primaryActionText,
    secondaryActionText,
    primaryAction,
    secondaryAction,
    primaryActionTemplate,
    secondaryActionTemplate,
    autofocus,
    forceReadout,
  }: Notification): void {
    this.notificationService.showNotification({
      ttl,
      header,
      message,
      modifier,
      dismissible,
      primaryAction,
      secondaryAction,
      primaryActionText,
      secondaryActionText,
      primaryActionTemplate,
      secondaryActionTemplate,
      autofocus,
      forceReadout,
    });
  }

  showMessage(buttonType: string): void {
    this.notificationService.showNotification({
      header: 'This is the header of notification',
      message: `The notification triggered by clicking on ${buttonType} button.`,
      dismissible: true,
      ttl: 0,
    });
  }
}
