import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BackbaseUiAngShowcaseModule, BackbaseUiAngExamplesModule } from '@backbase/ui-ang-examples';
import { IconModule } from '@backbase/ui-ang/icon';
import { LogoModule } from '@backbase/ui-ang/logo';
import { ModalModule } from '@backbase/ui-ang/modal';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';
import { ButtonModule } from '@backbase/ui-ang/button';
import { BadgeModule } from '@backbase/ui-ang/badge';
import { SearchBoxModule } from '@backbase/ui-ang/search-box';
import { BackbaseUiAngDocPagesModule, bbUiDocs } from '@backbase/ui-ang-doc-pages';
import { DsToolkitModule } from '@backbase/ds-toolkit';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MetadataService } from './services/metadata.service';
import { ScrollSpyService } from './services/scroll-spy.service';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';

import { SideMenuComponent } from './ui/side-menu/side-menu.component';
import { MiniMenuComponent } from './ui/mini-menu/mini-menu.component';
import { HeaderComponent } from './ui/header/header.component';
import { HeadingContainerComponent } from './ui/heading-container/heading-container.component';
import { TitleCleanPipe } from './pipes/title-clean.pipe';
import { ComponentShowcasePagesComponent } from './pages/_shared/component-showcase-pages/component-showcase-pages.component';
import { DocShowcasePagesComponent } from './pages/_shared/doc-showcase-pages/doc-showcase-pages.component';
import { FooterComponent } from './ui/footer/footer.component';
import { SearchBarComponent } from './ui/search-bar/search-bar.component';
import { DesignTokensComponent } from './ui/custom-elements/design-tokens/design-tokens.component';

// Landing page
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

// Components
import { ShowcaseComponent } from './pages/components/showcase/showcase.component';
import { ShowcaseDesignComponent } from './pages/components/showcase/design/showcase-design.component';
import { ShowcaseWebComponent } from './pages/components/showcase/web/showcase-web.component';

// Web theme
import { ThemeDocComponent, SafeHtmlPipe, SafeResourceUrlPipe } from './pages/web-theme/doc-page/theme-doc.component';

// Mobile
import { DesignTokenService } from './services/design-token.service';
import { TitleCaseExceptPipe } from './pipes/title-case-except.pipe';
import { ComponentMetaDataService } from './services/component-meta-data.service';
import { MobileShowcaseComponent } from './pages/mobile/mobile-showcase/mobile-showcase.component';
import { ShowcaseIOSComponent } from './pages/mobile/ios/showcase-ios.component';
import { ShowcaseAndroidComponent } from './pages/mobile/android/showcase-android.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MenuItemComponent } from './pages/_shared/menu-item-component/menu-item.component';
import { RenderHtmlComponent } from './pages/_shared/render-html/render-html.component';
import { ComponentListComponent } from './ui/custom-elements/component-list/component-list.component';
import { OverviewListComponent } from './ui/custom-elements/overview-list/overview-list.component';
import { TabsComponent } from './ui/tabs/tabs.component';
import { LayoutModule } from '@backbase/ui-ang/layout';
import { DesignTokenListComponent } from './ui/custom-elements/design-token-list/design-token-list.component';
import { ShowcaseStorybookComponent } from './pages/components/showcase/storybook/showcase-storybook.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    ThemeDocComponent,
    ShowcaseComponent,
    ShowcaseDesignComponent,
    ShowcaseWebComponent,
    ShowcaseStorybookComponent,
    MobileShowcaseComponent,
    ShowcaseIOSComponent,
    ShowcaseAndroidComponent,
    SideMenuComponent,
    MiniMenuComponent,
    HeaderComponent,
    HeadingContainerComponent,
    TitleCleanPipe,
    TitleCaseExceptPipe,
    ScrollSpyDirective,
    ComponentShowcasePagesComponent,
    DocShowcasePagesComponent,
    MenuItemComponent,
    RenderHtmlComponent,
    FooterComponent,
    SearchBarComponent,
    DesignTokensComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    BackbaseUiAngDocPagesModule,
    BackbaseUiAngExamplesModule,
    BackbaseUiAngShowcaseModule,
    IconModule,
    LogoModule,
    LayoutModule,
    ModalModule,
    ButtonModule,
    BadgeModule,
    LoadingIndicatorModule,
    SearchBoxModule,
    DsToolkitModule.forRoot({
      lib: null,
      exampleLib: BackbaseUiAngExamplesModule,
      showcaseLib: BackbaseUiAngShowcaseModule,
      docs: bbUiDocs,
    }),
    ComponentListComponent,
    OverviewListComponent,
    DesignTokenListComponent,
    TabsComponent,
    NotFoundComponent,
  ],
  providers: [
    ...environment.mockProviders,
    MetadataService,
    DesignTokenService,
    ScrollSpyService,
    ComponentMetaDataService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
